import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { connectMenu } from 'react-instantsearch-dom';

import {
  FormControl,
  NativeSelect,
  InputLabel,
  Input,
} from '@material-ui/core';

import { setAvailability } from '../state/finder';

// Translate Availability Labels (that we know of)
function translateAvailability(label) {
  switch (label) {
    case 'premise_on':
      return 'Stay and Enjoy';

    case 'premise_off':
      return 'Take it To Go';

    default:
      return label;
  }
}

const AvailabilitySelect = ({ availability, setAvailability, items }) => (
  <FormControl className="availability-select" fullWidth>
    <InputLabel htmlFor="filter-availability" shrink>Stay / To Go</InputLabel>
    <NativeSelect
      value={availability}
      onChange={event => setAvailability(event.target.value)}
      input={<Input name="availability" id="filter-availability" />}
    >
      <option value="">All</option>
      {items.map(item => (
        <option key={item.label} value={item.label}>
          {translateAvailability(item.label)}
        </option>
      ))}
    </NativeSelect>
  </FormControl>
);

// Connect AvailabilitySelect to Algolia
const ConnectedAvailabilitySelect = connectMenu(AvailabilitySelect);

const mapStateToProps = ({ finder }) => ({
  availability: finder.availability,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setAvailability,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedAvailabilitySelect);
