import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  FormControl,
  InputLabel,
  NativeSelect,
  Input,
  FormHelperText,
} from '@material-ui/core';

import { setProximity } from '../state/finder';

const ProximitySelect = ({ proximity, setProximity }) => (
  <FormControl className="proximity-select">
    <InputLabel htmlFor="filter-proximity">Distance</InputLabel>
    <NativeSelect
      value={proximity}
      onChange={event => setProximity(event.target.value)}
      input={<Input name="proximity" id="filter-proximity" />}
    >
      <option value={5}>5 miles</option>
      <option value={10}>10 miles</option>
      <option value={20}>20 miles</option>
      <option value={50}>50 miles</option>
      <option value={100}>100 miles</option>
    </NativeSelect>
    {proximity > 50 && (
      <FormHelperText>
        A maximum of 500 accounts are shown.
      </FormHelperText>
    )}
  </FormControl>
);

const mapStateToProps = ({ finder }) => ({
  proximity: finder.proximity,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setProximity,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProximitySelect);
