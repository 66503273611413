import React from 'react';

import { ReactComponent as Draft } from '../icons/draft.svg';
import { ReactComponent as Can } from '../icons/can.svg';
import { ReactComponent as Bottle } from '../icons/bottle.svg';

import capitalize from '../utilities/capitalize';

const icons = {
  Draft,
  Can,
  Bottle,
};

const FormatIcon = (props) => {
  const { format, ...otherProps } = props;

  // Define the icon based on the format input
  const Icon = icons[capitalize(format)];
  return <Icon {...otherProps} />;
};

export default FormatIcon;
