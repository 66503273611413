import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import classnames from 'classnames';

import {
  Typography,
  Button,
  Divider,
  Tooltip,
} from '@material-ui/core';
import { ArrowBackIos, LocationOn } from '@material-ui/icons';

import metersToMiles from '../utilities/metersToMiles';
import googleMapsLink from '../utilities/googleMapsLink';
import capitalize from '../utilities/capitalize';

import { unsetAccount } from '../state/finder';

import FormatIcon from './FormatIcon';

// Account Popover Header
const AccountPopoverHeader = ({ unsetAccount }) => (
  <header className="account-popover-header">
    <Button
      variant="contained"
      color="primary"
      onClick={() => unsetAccount()}
      fullWidth
      className="back"
    >
      <ArrowBackIos style={{ width: 12, height: 12 }} /> Back
    </Button>
  </header>
);

// Account Details
const AccountDetails = ({ account }) => (
  <div className="account-details padding-box">
    <Typography component="h2" variant="h3" gutterBottom>
      {account.name}
    </Typography>
    <Typography component="p" className="address">
      {account.street}<br />
      {account.city}, {account.state} {account.zip}
    </Typography>
    {account._rankingInfo.geoDistance && (
      <Typography component="p" className="proximity">
        <LocationOn fontSize="small" className="location-icon" /> {metersToMiles(account._rankingInfo.geoDistance)} miles
      </Typography>
    )}
    <Button
      color="primary"
      variant="contained"
      size="large"
      href={googleMapsLink([account.street, account.city, account.state, account.zip])}
      target="_blank"
    >
      Get Directions
    </Button>
  </div>
);

// Account Products
const AccountProducts = ({ products, productString }) => {
  const productsByBrand = [];

  // Loop through products and group by brand
  products.forEach((product) => {
    // If the brand array doesn't exist yet, add it
    if (!productsByBrand[product.brand_name]) {
      productsByBrand[product.brand_name] = [];
      productsByBrand[product.brand_name].formats = [];
    }

    // Add the product to the brand's array
    productsByBrand[product.brand_name].push(product);

    // And capture any new formats
    if (!productsByBrand[ product.brand_name ].formats.includes(product.format) && 'unknown' !== product.format) {
        productsByBrand[ product.brand_name ].formats.push(product.format)
    }
  });

  return (
    <div className="account-products">
      <div className="padding-box">
        {products.length ? (
          <>
            <Typography component="h3" variant="h4" gutterBottom>
              Available {productString}
            </Typography>
            <Typography component="p" className="note">
              We strive to give you the freshest data but product availability is subject to change.
              Call ahead to ensure they have what you&#39;re looking for.
            </Typography>
          </>
        ) : (
          <Typography component="p" className="note">
            We don&#39;t have specific data on the products here.
            Call ahead to ensure they have what you&#39;re looking for.
          </Typography>
        )}
      </div>
      {productsByBrand && Object.keys(productsByBrand).map(brand => (
        <div className="brand" key={brand}>
          <Typography component="h4" variant="h6">{brand}</Typography>
          <div className="formats">
            {productsByBrand[brand].formats.map( format => (
              <div className="product" key={format}>
                <Tooltip title={capitalize(format)} placement="top">
                  <div className="format">
                    <FormatIcon format={format} className="format-icon" />
                    <Typography variant="srOnly">Available in {format}</Typography>
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

// eslint-disable-next-line react/prefer-stateless-function
class AccountPopover extends React.PureComponent {
  render() {
    const { account, productStrings, unsetAccount } = this.props;

    return (
      <div className={classnames('account-popover', { in: account })}>
        <AccountPopoverHeader unsetAccount={unsetAccount} />
        {account && (
          <div className="account">
            <AccountDetails account={account} />
            <Divider />
            {account.products && <AccountProducts products={account.products} productString={productStrings.single} />}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ finder, app }) => ({
  account: finder.account,
  productStrings: {
    single: app.organization.product_string_single,
    plural: app.organization.product_string_plural,
  },
});

const mapDispatchToProps = dispatch => bindActionCreators({
  unsetAccount,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPopover);
