import React from 'react';

import Loader from '../loader.svg';

import PoweredBy from '../components/PoweredBy';

export default () => (
  <div className="view--loading">
    <img src={Loader} alt="Loading Animation" />
    <PoweredBy />
  </div>
);
