import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import { FilterList } from '@material-ui/icons';

import ProximitySelect from './ProximitySelect';
import BrandSelect from './BrandSelect';
import AvailabilitySelect from './AvailabilitySelect';

import { resetFilters, areFiltersModified } from '../state/finder';

// Window Resize Trigger Function
function triggerWindowResize() {
  window.dispatchEvent(new Event('resize'));
}

const Filters = ({ filtersModified, organizationHasProducts, resetFilters }) => (
  <Accordion className="filters-wrap" TransitionProps={{ onExited: () => triggerWindowResize() }}>
    <AccordionSummary className="filters-toggle-wrap">
      <Button
        variant="contained"
        color="primary"
        className="filters-toggle"
      >
        <FilterList fontSize="small" />&nbsp;
        More Filters
      </Button>
      {filtersModified && (
        <Button
          variant="contained"
          color="primary"
          className="filters-reset"
          onClick={(event) => {
            event.stopPropagation();
            resetFilters();
          }}
        >
          Reset
        </Button>
      )}
    </AccordionSummary>
    <AccordionDetails className="filters">
      <div className="filter filter--proximity">
        <ProximitySelect />
      </div>
      {organizationHasProducts && (
        <div className="filter filter--brands">
          <BrandSelect attribute="products.brand_name" limit={20} />
        </div>
      )}
      <div className="filter filter--availability">
        <AvailabilitySelect attribute="availability" />
      </div>
    </AccordionDetails>
  </Accordion>
);

const mapStateToProps = ({
  finder,
  app,
}) => ({
  filtersModified: areFiltersModified(finder),
  organizationHasProducts: app.organization.has_products,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  resetFilters,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
