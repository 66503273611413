import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Route, Switch, Redirect } from 'react-router-dom';

import fetch from 'cross-fetch';

import { Helmet } from 'react-helmet';
import BodyClassName from 'react-body-classname';
import Div100vh from 'react-div-100vh';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import Loading from './Loading';
import Location from './Location';
import Finder from './Finder';
import Error from './Error';

import themeOptions from '../themeOptions';

import { hydrateOrganization } from '../state/app';

// eslint-disable-next-line react/prefer-stateless-function
class App extends React.Component {
  componentDidMount() {
    const { hydrateOrganization } = this.props;

    // Get isEUCountry from abverify.com API before loading the app up
    fetch('https://abverify.com/ipcheck.aspx?T=2CECF070-1B17-4514-BE3B-2E6EE9E2ADF9')
      .then(
        response => response.json(),
        error => console.warn(error),
      )
      .then((response) => {
        if (response.Status === 'OK' && response.IsEUCountry === false) {
          // Get the window's host for our API call
          let { host: domain } = window.location;

          // Allow custom domain override via env variable
          if (process.env.REACT_APP_TEST_DOMAIN) {
            domain = process.env.REACT_APP_TEST_DOMAIN;
          }

          if (domain) {
            // Grab the data from the frontend endpoint
            fetch(`/api/organizations?domain=${domain}`)
              .then(
                response => response.json(),
                error => console.warn(error),
              )
              .then((data) => {
                // If there is an error
                if (data.error) {
                  throw new Error(data.error);
                }

                // Make sure there is data
                if (data[0] && data[0].id) {
                  // Hydrate the organization data
                  hydrateOrganization(data[0]);
                }
              });
          }
        } else {
          window.location = 'https://www.instagram.com/wickedweedbrewing/';
        }
      });
  }

  render() {
    const { loading, organization, queryParams } = this.props;

    // Make and Provide a Material UI Theme
    const theme = createMuiTheme({
      ...themeOptions,
      palette: {
        primary: {
          main: organization.primary_color ? organization.primary_color : '#000000',
          contrastText: organization.primary_text_color ? organization.primary_text_color : '#fff',
        },
        secondary: {
          main: '#111111',
        },
      },
    });

    return (
      <Div100vh className="app-wrap">
        {loading ? (
          <BodyClassName className="loading">
            <Loading />
          </BodyClassName>
        ) : (
          <BodyClassName className="loaded">
            <MuiThemeProvider theme={theme}>
              <Helmet>
                <title>{organization.name} {organization.product_string_single} Finder</title>
                <meta name="description" content={`Find ${organization.name} beers near you!`} />
              </Helmet>
              <Switch>
                <Route exact path="/" render={() => <Location />} />
                <Route exact path="/:lat,:lng/" render={match => <Finder geo={match.match.params} />} />
                <Route exact path="//" strict render={() => <Redirect to={`/${queryParams}`} />} />
                <Route render={() => <Error />} />
              </Switch>
            </MuiThemeProvider>
          </BodyClassName>
        )}
      </Div100vh>
    );
  }
}

const mapStateToProps = ({ router, app }) => ({
  loading: app.isLoading,
  organization: app.organization,
  queryParams: router.location.search,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  hydrateOrganization,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
