export const HYDRATE_ORGANIZATION = 'app/HYDRATE_ORGANIZATION';

export const initialState = {
  isLoading: true,
  organization: {
    id: false,
    domain: false,
    name: false,
    slug: false,
    logo_url: false,
    background_url: false,
    primary_color: false,
    primary_text_color: false,
    site_url: false,
    intro_text: false,
    last_import_date: false,
    has_products: false,
    product_string_single: false,
    product_string_plural: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE_ORGANIZATION:
      return {
        ...state,
        organization: action.data,
        isLoading: false,
      };

    default:
      return state;
  }
};

export const hydrateOrganization = data => (dispatch) => {
  // Hydrate the App
  dispatch({
    type: HYDRATE_ORGANIZATION,
    data,
  });
};
