import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import classNames from 'classnames';
import Geolocation from 'react-geolocation';

import Button from '@material-ui/core/Button';
import { GpsFixed } from '@material-ui/icons';

import { setLocation, changeLocation } from '../state/location';

// eslint-disable-next-line react/prefer-stateless-function
class Geolocator extends React.PureComponent {
  getButtonText(fetchingPosition, error, children) {
    if (fetchingPosition) {
      return 'Getting your location...';
    }

    if (error) {
      return 'Can\'t find you, try a search';
    }

    return children;
  }

  handleSuccess(position) {
    const { setLocation, changeLocation, queryParams } = this.props;

    const latLng = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    // Set location
    setLocation(
      'Your Location',
      latLng,
      'geolocator',
    );

    // Go to the map
    changeLocation(latLng, queryParams);
  }

  render() {
    const { children } = this.props;

    return (
      <Geolocation
        lazy
        render={({
          fetchingPosition,
          error,
          getCurrentPosition,
        }) => (
          <Button
            className={
              classNames(
                'geolocator',
                { error },
                { fetching: fetchingPosition },
              )
            }
            variant="contained"
            color="primary"
            fullWidth
            onClick={getCurrentPosition}
          >
            <GpsFixed fontSize="small" className="icon" />&nbsp;&nbsp;
            {this.getButtonText(fetchingPosition, error, children)}
          </Button>
        )}
        onSuccess={position => this.handleSuccess(position)}
      />
    );
  }
}

const mapStateToProps = ({ router }) => ({
  queryParams: router.location.search,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setLocation,
  changeLocation,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Geolocator);
