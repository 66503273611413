import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import classnames from 'classnames';

import { InputBase, InputAdornment, MenuItem } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import { setLocation, changeLocation } from '../state/location';

// eslint-disable-next-line react/prefer-stateless-function
class Autocomplete extends React.PureComponent {
  state = { address: this.props.currentAddress || '' }; // eslint-disable-line

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    const { setLocation, changeLocation, queryParams } = this.props;

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then((latLng) => {
        // Update the address
        this.setState({ address });
        // Set the Location
        setLocation(address, latLng, 'autocomplete');
        // Change the location
        changeLocation(latLng, queryParams);
      })
      .catch(error => console.error('Error', error));
  };

  // Clear and focus the input
  clearAndFocusInput = () => {
    this.setState({ address: '' });
    this.textInput.focus();
  };

  render() {
    const { address } = this.state;

    return (
      <PlacesAutocomplete
        value={address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading,
        }) => (
          <div className="autocomplete">
            <InputBase
              {...getInputProps({
                placeholder: 'Enter city, zip, or address...',
                type: 'search',
                fullWidth: true,
                className: 'location-search-input',
                inputRef: input => this.textInput = input,
                endAdornment: address ?
                  <InputAdornment position="end" onClick={this.clearAndFocusInput} className="input-clear">
                    <Clear fontSize="small" className="icon" />
                  </InputAdornment>
                  : false,
              })}
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.map(suggestion => (
                <MenuItem
                  {...getSuggestionItemProps(suggestion)}
                  className={classnames('suggestion-item', { active: suggestion.active })}
                  component="div"
                >
                  <span className="suggestion">{suggestion.description}</span>
                </MenuItem>
              ))}
              {loading && (
                <MenuItem className="suggestion-item" component="div">Loading...</MenuItem>
              )}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

const mapStateToProps = ({ router, location }) => ({
  currentAddress: location.address,
  geo: location.geo,
  queryParams: router.location.search,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setLocation,
  changeLocation,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Autocomplete);
