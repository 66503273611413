import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { connectHits } from 'react-instantsearch-dom';

import ReactMapboxGl, {
  ZoomControl,
  Layer,
  Feature,
  Marker,
  Popup,
} from 'react-mapbox-gl';

import { Typography } from '@material-ui/core';

import getBounds from '../utilities/getBounds';

import {
  setAccount,
} from '../state/finder';

const ReactMap = ReactMapboxGl({
  minZoom: 8,
  maxZoom: 16,
  accessToken: process.env.REACT_APP_MAPBOX_KEY,
  customAttribution: '<strong>'
    + '<a href="https://craftpeak.com?utm_source=beerquest" target="_blank" rel="noopener noreferrer">❤ Craftpeak</a>'
    + '</strong>',
});

// eslint-disable-next-line react/prefer-stateless-function
class Map extends React.PureComponent {
  state = {
    mapPristine: true,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hits: nextAccounts } = nextProps;
    const { hits: accounts } = this.props;

    // Reset mapPristine only if the accounts have changed
    if (nextAccounts.length !== accounts.length) {
      this.setState({ mapPristine: true });
    }
  }

  render() {
    const {
      center,
      mobile,
      hits: accounts,
      activeAccount,
      setAccount,
    } = this.props;

    const { mapPristine } = this.state;

    // Set a default
    let fitBoundsProps = {
      center,
    };

    // If there is an active account, zoom into it, unless we're on mobile
    if (mobile && !mapPristine) {
      fitBoundsProps = {};
    } else if (activeAccount && !mobile) {
      fitBoundsProps = {
        center,
        fitBounds: getBounds([center, [activeAccount._geoloc.lng, activeAccount._geoloc.lat]]),
        fitBoundsOptions: { padding: 100 },
      };
    } else {
      // Else, calculate positions for all of the accounts
      const accountPositions = accounts.map(account => [account._geoloc.lng, account._geoloc.lat]);

      fitBoundsProps = {
        center,
        fitBounds: getBounds([center, ...accountPositions]),
        fitBoundsOptions: { padding: mobile ? 50 : 100 },
      };
    }

    return (
      <div className="map">
        <ReactMap
          // eslint-disable-next-line react/style-prop-object
          style="mapbox://styles/craftpeak/cjumlha1u3uy91fnq4cpgaz4p"
          containerStyle={{
            height: '100%',
            width: '100%',
          }}
          {...fitBoundsProps}
          maxZoom={16}
          onDragEnd={() => this.setState({ mapPristine: false })}
          onTouchEnd={() => this.setState({ mapPristine: false })}
        >
          <ZoomControl />
          <Layer type="symbol" id="on-premise" layout={{ 'icon-image': 'beer-finder_pint-dark-40', 'icon-allow-overlap': true }}>
            {accounts.filter(account => account.availability.includes('premise_on')).map(account => (
              <Feature
                key={account.id}
                coordinates={[account._geoloc.lng, account._geoloc.lat]}
                onClick={() => setAccount(account)}
              />
            ))}
          </Layer>
          <Layer type="symbol" id="off-premise" layout={{ 'icon-image': 'beer-finder_cart-dark-40', 'icon-allow-overlap': true }}>
            {accounts.filter(account => account.availability.includes('premise_off')).map(account => (
              <Feature
                key={account.id}
                coordinates={[account._geoloc.lng, account._geoloc.lat]}
                onClick={() => setAccount(account)}
              />
            ))}
          </Layer>
          <Layer type="symbol" id="location" layout={{ 'icon-image': 'beer-finder_map-pin-40', 'icon-allow-overlap': true }}>
            <Marker coordinates={center} />
          </Layer>
          {activeAccount && (
            <Popup
              key={activeAccount.id}
              coordinates={[activeAccount._geoloc.lng, activeAccount._geoloc.lat]}
            >
              <Typography variant="h6">{activeAccount.name}</Typography>
            </Popup>
          )}
        </ReactMap>
      </div>
    );
  }
}

const ConnectedMap = connectHits(Map);

const mapStateToProps = ({ finder }) => ({
  activeAccount: finder.account,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setAccount,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedMap);
