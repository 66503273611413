export default coordinates => [
  [
    Math.min(...coordinates.map(coord => coord[0])),
    Math.min(...coordinates.map(coord => coord[1])),
  ],
  [
    Math.max(...coordinates.map(coord => coord[0])),
    Math.max(...coordinates.map(coord => coord[1])),
  ],
];
