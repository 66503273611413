import React from 'react';
import { connect } from 'react-redux';

import Imgix, { Background } from 'react-imgix';

import Typography from '@material-ui/core/Typography';

import Autocomplete from '../components/Autocomplete';
import Geolocator from '../components/Geolocator';
import PoweredBy from '../components/PoweredBy';

import createMarkup from '../utilities/createMarkup';

const Location = ({
  organizationBackground,
  organizationSiteUrl,
  organizationLogo,
  organizationIntroText,
  organizationProductStringSingle,
}) => (
  <Background
    className="container--location"
    src={organizationBackground}
    imgixParams={{
      auto: 'compress',
      w: (window.innerWidth > 800) ? 1600 : 800,
      h: (window.innerWidth > 800) ? 1067 : 533,
    }}
  >
    <div className="location-box">
      <header className="location-box-header">
        <div className="logo-wrap">
          <a href={organizationSiteUrl} rel="noopener noreferrer">
            <Imgix src={organizationLogo} height={120} />
          </a>
        </div>
        <Typography variant="h1" align="center" gutterBottom={organizationIntroText.length > 0}>
          {organizationProductStringSingle} Finder
        </Typography>
        {organizationIntroText.length > 0 &&
          <Typography variant="caption" align="center" component="div">
            <span dangerouslySetInnerHTML={createMarkup(organizationIntroText)} />
          </Typography>
        }
      </header>
      <Autocomplete />
      <Typography variant="h5" component="div" align="center" className="or">- OR -</Typography>
      <Geolocator>
        Use my location
      </Geolocator>
    </div>
    <PoweredBy />
  </Background>
);

const mapStateToProps = ({ app, location }) => ({
  organizationLogo: app.organization.logo_url,
  organizationSiteUrl: app.organization.site_url,
  organizationBackground: app.organization.background_url,
  organizationIntroText: app.organization.intro_text,
  organizationProductStringSingle: app.organization.product_string_single,
  geo: location.geo,
});

export default connect(
  mapStateToProps,
)(Location);
