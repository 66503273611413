import React from 'react';

import { Typography } from '@material-ui/core';

export default () => (
  <div className="container--error">
    <Typography variant="h1" component="h1">
      Something went wrong, sorry.
    </Typography>
    <Typography component="p">
      <a href="https://craftpeak.com/contact/">Leave us a note?</a>
    </Typography>
  </div>
);
