import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { replace } from 'connected-react-router';

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import qs from 'qs';
import Media from 'react-media';

import { Button } from '@material-ui/core';

import Autocomplete from '../components/Autocomplete';
import Filters from '../components/Filters';
import AccountsList from '../components/AccountsList';
import Map from '../components/Map';
import AccountPopover from '../components/AccountPopover';
import NoAccounts from '../components/NoAccounts';

import milesToMeters from '../utilities/milesToMeters';

import { setBrand, setAvailability } from '../state/finder';

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_INDEX,
  process.env.REACT_APP_ALGOLIA_KEY,
);

// eslint-disable-next-line react/prefer-stateless-function
class Finder extends React.PureComponent {
  state = {
    mapOpen: true,
  };

  // Update Store based on Query Params (on load)
  componentDidMount() {
    const { queryParams, setBrand, setAvailability } = this.props;

    // Set Brand
    if (queryParams.brand) {
      setBrand(queryParams.brand);
    }

    // Set Availability
    if (queryParams.availability) {
      setAvailability(queryParams.availability);
    }
  }

  // Set Query Params based on Store
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Get new props
    const {
      brand: nextBrand,
      availability: nextAvailability,
    } = nextProps;


    // Get old props
    const {
      brand,
      availability,
      setQueryParams,
    } = this.props;

    // Make sure the props are actually different
    if (nextBrand !== brand || nextAvailability !== availability) {
      const searchString = qs.stringify({
        brand: nextBrand || undefined,
        availability: nextAvailability || undefined,
      });

      setQueryParams(searchString);
    }
  }

  render() {
    const {
      geo,
      organizationId,
      organizationName,
      proximity,
      brand,
      availability,
    } = this.props;

    const { mapOpen } = this.state;

    // Get lat, lng from url
    const { lat, lng } = geo;

    // Configure props have to be dynamic, here are the default ones
    const configureProps = {
      filters: `organization_id:${organizationId}`,
      hitsPerPage: 500,
      analyticsTags: [organizationName],
      getRankingInfo: true,
    };

    // Bounds Props defaults
    const boundsProps = {
      aroundLatLng: `${lat}, ${lng}`,
      aroundRadius: milesToMeters(proximity),
    };

    return (
      <InstantSearch
        searchClient={searchClient}
        indexName="production_accounts"
        searchState={{
          menu: {
            'products.brand_name': brand,
            availability,
          },
        }}
      >
        <Configure
          {...configureProps}
          {...boundsProps}
        />
        <div className="container--finder">
          <div className="sidebar-wrap">
            <div className="sidebar">
              <Autocomplete />
              <Filters />
              <Media query={{ maxWidth: 1023 }}>
                {matches => matches && mapOpen ? ( // eslint-disable-line no-confusing-arrow
                  <Map center={[Number(lng), Number(lat)]} mobile />
                ) : (
                  <AccountsList />
                )}
              </Media>
              <AccountPopover />
            </div>
          </div>
          <Media query={{ minWidth: 1024 }}>
            {matches => matches ? ( // eslint-disable-line no-confusing-arrow
              <Map center={[Number(lng), Number(lat)]} mobile={false} />
            ) : (
              <div className="map-toggle-wrap">
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => this.setState({ mapOpen: !mapOpen })}
                  className="map-toggle"
                >
                  View the {mapOpen ? 'List' : 'Map'}
                </Button>
              </div>
            )}
          </Media>
          <NoAccounts />
        </div>
      </InstantSearch>
    );
  }
}

const mapStateToProps = ({
  router,
  app,
  finder,
}) => ({
  queryParams: qs.parse(router.location.search, { ignoreQueryPrefix: true }),
  organizationId: app.organization.id,
  organizationName: app.organization.name,
  proximity: finder.proximity,
  brand: finder.brand,
  availability: finder.availability,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setBrand,
  setAvailability,
  setQueryParams: params => replace({ search: params }),
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Finder);
