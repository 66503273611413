import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { connectHits } from 'react-instantsearch-dom';

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { LocationOn, ArrowForwardIos } from '@material-ui/icons';

import metersToMiles from '../utilities/metersToMiles';

import { setAccount } from '../state/finder';

const SecondaryText = ({ distance }) => (
  <span className="secondary">
    <LocationOn className="location-icon" />{distance} mi.
  </span>
);

const AccountsList = ({ hits: accounts, setAccount }) => (
  <div className="accounts-list">
    <List style={{ padding: 0 }}>
      {accounts.map(account => (
        <ListItem key={account.id} button onClick={() => setAccount(account)} className="account">
          <ListItemText
            primary={account.name}
            secondary={account._rankingInfo.geoDistance
              ? <SecondaryText distance={metersToMiles(account._rankingInfo.geoDistance)} />
              : false
            }
            style={{ margin: 0 }}
          />
          <ListItemSecondaryAction className="arrow-icon-wrap">
            <ArrowForwardIos className="arrow-icon" />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  </div>
);

const ConnectedAccountsList = connectHits(AccountsList);

const mapDispatchToProps = dispatch => bindActionCreators({
  setAccount,
}, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(ConnectedAccountsList);
