import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { connectMenu } from 'react-instantsearch-dom';

import {
  FormControl,
  InputLabel,
  Input,
  NativeSelect,
} from '@material-ui/core';

import { setBrand } from '../state/finder';

const BrandSelect = ({ brand, productStrings, setBrand, items }) => (
  <FormControl className="brand-select" fullWidth>
    <InputLabel htmlFor="filter-brand" shrink>{productStrings.single}</InputLabel>
    <NativeSelect
      value={brand}
      onChange={event => setBrand(event.target.value)}
      input={<Input name="brand" id="filter-brand" />}
    >
      <option value="">All {productStrings.plural}</option>
      {items.map(item => (
        <option key={item.label} value={item.label}>
          {item.label}
        </option>
      ))}
    </NativeSelect>
  </FormControl>
);

// Connect BrandSelect to Algolia
const ConnectedBrandSelect = connectMenu(BrandSelect);

const mapStateToProps = ({ finder, app }) => ({
  brand: finder.brand,
  productStrings: {
    single: app.organization.product_string_single,
    plural: app.organization.product_string_plural,
  },
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setBrand,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedBrandSelect);
