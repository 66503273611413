import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Link as RouterLink } from 'react-router-dom';

import { connectStateResults } from 'react-instantsearch-dom';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Link,
} from '@material-ui/core';

import { setProximity, resetFilters, areFiltersModified } from '../state/finder';

class NoAccounts extends React.Component {
  state = {
    proximityClicked: false,
    resetClicked: false,
  };

  handleProximityClick() {
    const { setProximity } = this.props;

    this.setState({ proximityClicked: true });
    setProximity(100);
  }

  handleResetClick() {
    const { resetFilters } = this.props;

    this.setState({ resetClicked: true });
    resetFilters();
  }

  render() {
    const {
      searching,
      searchResults,
      siteUrl,
      proximity,
      brand,
      availability,
    } = this.props;

    const { proximityClicked, resetClicked } = this.state;

    return (
      <Dialog
        open={!!(!searching && searchResults && searchResults.hits.length === 0)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" disableTypography>
          <Typography variant="h2">No accounts found.</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sorry, we can&#39;t find what you&#39;re looking for.
            Try searching further, resetting your filters, or searching somewhere else.
            There&#39;s a chance we might not distribute to your area yet!
            {siteUrl && (
              <>
                &nbsp;<Link href={siteUrl}>Visit our website</Link> for more information.
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(proximity !== 100 && !proximityClicked) && (
            <Button onClick={() => this.handleProximityClick(100)} color="primary" autoFocus>
              Search Further
            </Button>
          )}
          {(areFiltersModified(proximity, brand, availability) && !resetClicked) ? (
            <Button onClick={() => this.handleResetClick()} color="primary" variant="contained" autoFocus>
              Reset Filters
            </Button>
          ) : (
            <Button component={RouterLink} to="/" color="primary" variant="contained">
              Change Your Location
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

const ConnectedNoAccounts = connectStateResults(NoAccounts);

const mapStateToProps = ({ app, finder }) => ({
  siteUrl: app.organization.site_url,
  proximity: finder.proximity,
  brand: finder.brand,
  availability: finder.availability,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setProximity,
  resetFilters,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedNoAccounts);
